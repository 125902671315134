header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9; }
  header > .container {
    padding: 0 30px; }
  header .header {
    height: 170px;
    width: 100%;
    background-color: #000; }
    @media only screen and (max-width: 1023px) {
      header .header {
        height: 100px; } }
    header .header .row {
      align-items: center; }
    header .header .telephone .phone {
      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      margin-left: 10px; }
    @media only screen and (max-width: 1023px) {
      header .header .telephone {
        display: none; } }
    @media only screen and (max-width: 1023px) {
      header .header .logo svg {
        height: 100px;
        width: 182px;
        position: fixed;
        top: 0;
        left: 50%;
        margin-left: -91px; } }
    header .header .social {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media only screen and (max-width: 1023px) {
        header .header .social {
          display: none; } }
      header .header .social .text {
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
        margin-right: 10px; }
      header .header .social .isvg {
        margin: 0 5px; }
    header .header .menu {
      display: none; }
      header .header .menu button {
        background-color: transparent;
        border: 0;
        outline: 0;
        float: right; }
      @media only screen and (max-width: 1023px) {
        header .header .menu {
          display: block;
          position: fixed;
          right: 0px;
          top: 50px;
          margin-top: -15px; } }
  header .navigation {
    width: 100%;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 1023px) {
      header .navigation {
        display: none; } }
    header .navigation li {
      padding: 30px 0;
      font-size: 16px;
      font-weight: 700; }
      header .navigation li a {
        position: relative;
        color: #000;
        text-decoration: none; }
        header .navigation li a::after {
          content: " ";
          position: absolute;
          bottom: -20px;
          left: 50%;
          margin-left: -4px;
          height: 8px;
          width: 8px;
          border-radius: 4px;
          background-color: #c1a35e;
          display: none; }
      header .navigation li:hover a {
        color: #c1a35e;
        /*&::after{
                        display: block;
                    }*/ }
    header .navigation .active a {
      color: #c1a35e; }
  header .navigation-container {
    position: relative; }
    header .navigation-container .dot {
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background-color: #c1a35e;
      position: absolute;
      top: 50%;
      margin-top: 20px; }
      @media only screen and (max-width: 1023px) {
        header .navigation-container .dot {
          display: none; } }
  header .mobile-close {
    display: none; }
    @media only screen and (max-width: 1023px) {
      header .mobile-close {
        display: block;
        background-color: transparent;
        border: 0;
        outline: none;
        position: fixed;
        top: 32px;
        right: 32px;
        -webkit-animation-name: rotate-menu;
        /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: 1s;
        /* Safari 4.0 - 8.0 */
        animation-name: rotate-menu;
        animation-duration: 1s;
        /* Safari 4.0 - 8.0 */
        /* Standard syntax */ }
      @-webkit-keyframes rotate-menu {
        from {
          transform: rotate(0deg); }
        to {
          transform: rotate(360deg); } }
      @keyframes rotate-menu {
        from {
          transform: rotate(0deg); }
        to {
          transform: rotate(360deg); } } }
  header .mobile-social {
    display: none; }
    @media only screen and (max-width: 1023px) {
      header .mobile-social {
        display: block;
        background-color: transparent;
        border: 0;
        outline: none;
        position: fixed;
        bottom: 32px;
        right: 32px; }
        header .mobile-social a {
          margin: 0 5px; } }
  header .navigation-mobile {
    position: fixed;
    top: 0;
    left: 20%;
    width: 80%;
    min-height: 100vh;
    background-color: #000;
    display: none;
    padding: 64px;
    -webkit-animation-name: mobile-menu;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: mobile-menu;
    animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }

@-webkit-keyframes mobile-menu {
  from {
    left: 100%; }
  to {
    left: 20%; } }

@keyframes mobile-menu {
  from {
    left: 100%; }
  to {
    left: 20%; } }
    @media only screen and (max-width: 1023px) {
      header .navigation-mobile {
        display: block; } }
    header .navigation-mobile li {
      padding: 16px 0; }
      header .navigation-mobile li a {
        color: #FFFFFF; }
        header .navigation-mobile li a::after {
          display: none; }
    header .navigation-mobile .active a::after {
      display: none; }

@media only screen and (min-width: 1023px) {
  .sticky {
    position: fixed;
    top: 37px;
    left: 0;
    width: 100%;
    z-index: 9; }
    .sticky .header {
      display: none; }
    .sticky .container {
      background-color: #FFFFFF;
      -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      border-radius: 91px;
      padding: 0 30px; } }
    @media only screen and (min-width: 1023px) and (max-width: 1023px) {
      .sticky .container {
        all: unset; } }

@media only screen and (min-width: 1023px) {
    .sticky .navigation {
      margin-bottom: 0; }
      .sticky .navigation li {
        padding: 23px 0; }
    .sticky .navigation-mobile {
      position: fixed;
      top: 0;
      left: 20%;
      width: 80%;
      min-height: 100vh;
      background-color: #000;
      display: block;
      padding: 64px; }
      .sticky .navigation-mobile li {
        cursor: pointer;
        padding: 16px 0; }
        .sticky .navigation-mobile li a {
          color: #FFFFFF; }
          .sticky .navigation-mobile li a::after {
            display: none; }
      .sticky .navigation-mobile .active a::after {
        display: none; } }

.section-1 h3 {
  font-size: 64px;
  font-weight: 900;
  margin-bottom: 25px;
  margin-top: 20px;
  color: #151F4C; }
  @media only screen and (max-width: 1023px) {
    .section-1 h3 {
      font-size: 48px; } }

.section-1 h6 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  color: #C1A35E;
  max-width: 432px; }
  @media only screen and (max-width: 767px) {
    .section-1 h6 {
      margin-bottom: 20px; } }

.section-1 ul {
  list-style: none;
  padding: 0; }
  .section-1 ul li {
    position: relative;
    padding: 8px 0 8px 36px;
    font-size: 18px;
    font-weight: 400;
    color: #000; }
    .section-1 ul li::before {
      content: " ";
      display: block;
      background-image: url(./assets/check.svg);
      width: 24px;
      height: 19px;
      position: absolute;
      top: 16px;
      left: 0; }

.section-1 .animation-container {
  width: 670px; }
  @media only screen and (max-width: 767px) {
    .section-1 .animation-container {
      width: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .section-1 .animation-container {
      width: 100%; } }

.section-1 img {
  width: 670px; }
  @media only screen and (max-width: 767px) {
    .section-1 img {
      width: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .section-1 img {
      width: 100%; } }

.section-1 .scroll-down {
  margin-top: -80px; }
  @media only screen and (max-width: 767px) {
    .section-1 .scroll-down {
      margin-top: 0; } }
  .section-1 .scroll-down a {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 81px; }
    @media only screen and (max-width: 767px) {
      .section-1 .scroll-down a {
        margin-bottom: 20px; } }
    .section-1 .scroll-down a span {
      font-size: 10px;
      font-weight: 700;
      margin-top: 5px;
      color: #151F4C;
      max-width: 60px;
      text-align: center; }

.section-2 h3 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 34px;
  margin-top: 24px;
  color: #151F4C; }
  @media only screen and (max-width: 1023px) {
    .section-2 h3 {
      font-size: 36px; } }

.section-2 h6 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  color: #C1A35E;
  max-width: 432px; }

.section-2 p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 26px;
  color: #000; }

.section-2 ul {
  list-style: none;
  padding: 0; }
  .section-2 ul li {
    position: relative;
    padding: 8px 0 8px 36px;
    font-size: 18px;
    font-weight: 400;
    color: #000; }
    .section-2 ul li::before {
      content: " ";
      display: block;
      background-image: url(./assets/check.svg);
      width: 24px;
      height: 19px;
      position: absolute;
      top: 16px;
      left: 0; }

.section-2 .animation-container {
  width: 100%;
  margin-bottom: 89px; }
  @media only screen and (max-width: 767px) {
    .section-2 .animation-container {
      margin-bottom: 0px; } }

.section-2 img {
  width: 100%;
  margin-bottom: 89px; }
  @media only screen and (max-width: 767px) {
    .section-2 img {
      margin-bottom: 0px; } }

.section-3 {
  margin-top: 10px; }
  .section-3 h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 26px;
    color: #151F4C; }
  .section-3 h6 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    color: #C1A35E;
    max-width: 432px; }
  .section-3 p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 26px;
    color: #000; }
  .section-3 ul {
    list-style: none;
    padding: 0; }
    .section-3 ul li {
      position: relative;
      padding: 9px 0 9px 18px;
      font-size: 16px;
      font-weight: 400;
      color: #000; }
      .section-3 ul li::before {
        content: " ";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        position: absolute;
        background-color: #c1a35e;
        top: 16px;
        left: 0; }
  .section-3 .animation-container {
    width: 100%;
    margin-bottom: 89px; }
  .section-3 img {
    width: 100%;
    margin-bottom: 89px; }

.section-5 {
  position: relative;
  margin-top: 200px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-5 {
      margin-top: 50px; } }
  @media only screen and (max-width: 767px) {
    .section-5 {
      margin-top: 0px; }
      .section-5 .carousel {
        margin-top: 60px; } }
  .section-5 .image {
    width: 100%; }
  .section-5 button {
    background-color: transparent;
    border: 0;
    outline: none;
    margin-right: 30px;
    margin-top: 10px; }
  .section-5 .bg {
    position: absolute;
    left: 0;
    top: -200px;
    width: 39%; }
    @media only screen and (max-width: 767px) {
      .section-5 .bg {
        top: 0;
        width: 80%; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-5 .bg {
        top: -40px;
        width: 37%; } }
  .section-5 h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 22px;
    color: #151F4C; }
  .section-5 h6 {
    font-size: 20px;
    font-weight: 700;
    color: #C1A35E;
    margin-bottom: 28px; }
  .section-5 p {
    font-size: 18px;
    font-weight: 400;
    color: #000; }
  .section-5 .carousel-item {
    min-height: 350px; }

@media only screen and (max-width: 767px) {
  .section-4 {
    margin-top: 40px; } }

.section-4 h3 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 44px;
  margin-top: 24px;
  color: #151F4C; }
  @media only screen and (max-width: 1023px) {
    .section-4 h3 {
      font-size: 36px; } }

.section-4 h6 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #151F4C;
  max-width: 432px; }

.section-4 h4 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #151F4C; }

.section-4 button {
  background-color: #C1A35E;
  outline: none;
  border: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 24px;
  border-radius: 10px; }
  @media only screen and (max-width: 1023px) {
    .section-4 button {
      width: 100%; } }
  .section-4 button .overlay {
    background-color: #151F4C; }

.section-4 .reserve-button {
  background-color: #151F4C;
  outline: none;
  border: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 24px;
  border-radius: 10px;
  text-transform: uppercase;
  margin-left: 30px; }
  @media only screen and (max-width: 1023px) {
    .section-4 .reserve-button {
      margin-left: 0;
      margin-top: 30px;
      margin-bottom: 30px; } }
  .section-4 .reserve-button .overlay {
    background-color: #C1A35E; }

.section-4 p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 26px;
  color: #000; }

.section-4 ul {
  list-style: none;
  padding: 0; }
  .section-4 ul li {
    position: relative;
    padding: 8px 0 8px 36px;
    font-size: 18px;
    font-weight: 400;
    color: #000; }
    .section-4 ul li::before {
      content: " ";
      display: block;
      background-image: url(./assets/check.svg);
      width: 24px;
      height: 19px;
      position: absolute;
      top: 16px;
      left: 0; }

.section-4 .animation-container {
  width: 100%;
  margin-bottom: 89px; }
  @media only screen and (max-width: 1023px) {
    .section-4 .animation-container {
      margin-top: 40px; } }

.section-4 img {
  width: 100%;
  margin-bottom: 89px; }
  @media only screen and (max-width: 1023px) {
    .section-4 img {
      margin-top: 40px; } }

.section-6 {
  position: relative;
  margin-top: 173px; }
  @media only screen and (max-width: 767px) {
    .section-6 {
      margin-top: 0; } }
  .section-6 .image {
    width: 100%; }
  .section-6 button {
    background-color: #151F4C;
    outline: none;
    border: 0;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 43px;
    border-radius: 10px;
    float: right; }
    .section-6 button .overlay {
      background-color: #C1A35E; }
    @media only screen and (max-width: 767px) {
      .section-6 button {
        margin-top: 16px; } }
  .section-6 .bg {
    position: absolute;
    right: 0;
    top: -160px;
    width: 53%; }
    @media only screen and (max-width: 767px) {
      .section-6 .bg {
        top: 250px;
        width: 90%; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-6 .bg {
        top: -150px;
        width: 60%; } }
  .section-6 h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
    color: #151F4C; }
  .section-6 h3 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #151F4C; }
  .section-6 h6 {
    font-size: 18px;
    font-weight: 700;
    color: #151F4C;
    margin-bottom: 0; }
  .section-6 p {
    font-size: 18px;
    font-weight: 400;
    color: #000; }
  .section-6 a {
    color: #151F4C;
    text-decoration: underline; }
  .section-6 textarea {
    height: 160px; }

.map {
  height: 400px;
  width: 100%; }

footer {
  margin-top: 100px; }
  @media only screen and (max-width: 767px) {
    footer {
      margin-top: 50px; } }
  footer .navigation {
    padding: 0;
    margin-top: 30px;
    padding-bottom: 16px;
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #babbbe;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      footer .navigation {
        display: none; } }
    footer .navigation li {
      padding: 30px 0;
      font-size: 16px;
      font-weight: 700; }
      footer .navigation li a {
        position: relative;
        color: #C1A35E;
        text-decoration: none; }
      footer .navigation li:hover a {
        color: #151F4C; }
    footer .navigation .active a {
      color: #151F4C; }
  footer .copyright {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding: 30px 0;
    margin-bottom: 0; }
  footer .created-by {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding: 30px 0;
    margin-bottom: 0;
    text-align: right; }
    footer .created-by a {
      font-weight: 700;
      color: #000; }

.blog-section {
  position: relative;
  margin-top: 80px;
  display: block; }
  @media only screen and (max-width: 767px) {
    .blog-section {
      margin-top: 10px; } }
  @media only screen and (max-width: 767px) {
    .blog-section .categories {
      margin-bottom: 30px; } }
  .blog-section .categories h3 {
    font-size: 26px;
    font-weight: 700;
    color: #151F4C;
    margin-bottom: 34px; }
  .blog-section .categories ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .blog-section .categories ul > li {
      border-bottom: 1px solid #e4e4e4; }
      .blog-section .categories ul > li a {
        padding: 10px 0;
        display: block;
        font-size: 20px;
        color: #000 !important;
        text-decoration: none; }
        .blog-section .categories ul > li a:hover {
          color: #151F4C;
          font-weight: 600; }
  .blog-section .articles article {
    margin-bottom: 78px; }
    .blog-section .articles article a {
      text-decoration: none; }
    .blog-section .articles article a > img {
      width: 100%;
      margin-bottom: 30px; }
    .blog-section .articles article > img {
      width: 100%;
      margin-bottom: 30px; }
    .blog-section .articles article .read-more {
      color: #C1A35E;
      font-size: 18px;
      font-weight: 700; }
      .blog-section .articles article .read-more svg {
        width: 22px;
        height: 14px; }
        .blog-section .articles article .read-more svg path {
          fill: #C1A35E; }
    .blog-section .articles article .content {
      font-size: 18px;
      color: 900;
      margin-bottom: 30px; }
    .blog-section .articles article h2 {
      font-size: 40px;
      color: #151F4C;
      font-weight: 700;
      line-height: 46px;
      margin-bottom: 17px; }
      @media only screen and (max-width: 767px) {
        .blog-section .articles article h2 {
          font-size: 28px;
          line-height: 30px; } }
    .blog-section .articles article .user {
      display: flex;
      margin-bottom: 28px; }
      .blog-section .articles article .user img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        margin-right: 30px; }
      .blog-section .articles article .user > div .username {
        font-size: 20px;
        font-weight: 600;
        color: #151F4C;
        margin-bottom: 6px; }
      .blog-section .articles article .user > div .date {
        color: #C1A35E;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0; }

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; }
  .modal-overlay .modal-wrap {
    background-color: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 600px;
    margin-left: -300px;
    margin-top: -235px;
    border-radius: 20px;
    padding: 30px; }
    @media only screen and (max-width: 767px) {
      .modal-overlay .modal-wrap {
        padding: 10px;
        width: 100%;
        margin-left: -50%;
        z-index: 9999;
        top: 0;
        margin-top: 100px;
        border-radius: 0;
        max-height: 100vh;
        overflow-y: scroll;
        padding-bottom: 100px; } }
    .modal-overlay .modal-wrap .done {
      text-align: center;
      padding-top: 40px; }
      .modal-overlay .modal-wrap .done > div {
        width: 180px;
        height: 180px;
        background-color: #f5f5f5;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px; }
        .modal-overlay .modal-wrap .done > div::after {
          content: " ";
          display: block;
          width: 50px;
          height: 90px;
          border-bottom: 15px solid #C1A35E;
          border-right: 15px solid #C1A35E;
          transform: rotate(45deg); }
      .modal-overlay .modal-wrap .done h3 {
        font-weight: 600; }
    .modal-overlay .modal-wrap .close-btn {
      border: 0;
      outline: none;
      background-color: transparent;
      position: absolute;
      right: 15px;
      top: 15px; }
      .modal-overlay .modal-wrap .close-btn::after {
        content: " ";
        display: block;
        width: 20px;
        height: 4px;
        background-color: #151F4C;
        transform: rotate(45deg);
        border-radius: 5px;
        margin-top: -4px; }
      .modal-overlay .modal-wrap .close-btn::before {
        content: " ";
        display: block;
        width: 20px;
        height: 4px;
        background-color: #151F4C;
        transform: rotate(-45deg);
        border-radius: 5px; }
    .modal-overlay .modal-wrap h2 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 24px;
      color: #151F4C; }
    .modal-overlay .modal-wrap .error {
      color: #F1646C; }
    .modal-overlay .modal-wrap .submit {
      background-color: #151F4C;
      outline: none;
      border: 0;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;
      padding: 16px 43px;
      border-radius: 10px;
      float: right; }
      .modal-overlay .modal-wrap .submit .overlay {
        background-color: #C1A35E; }
      @media only screen and (max-width: 767px) {
        .modal-overlay .modal-wrap .submit {
          margin-top: 16px; } }

.share-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px; }

.share-actions button {
  width: 48px;
  height: 48px;
  margin-right: 15px; }
  @media only screen and (max-width: 767px) {
    .share-actions button {
      margin-right: 8px;
      width: 32px;
      height: 32px; }
      .share-actions button svg {
        width: 32px;
        height: 32px; } }

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%; }

.ol-zoom-in,
.ol-zoom-out {
  width: 35px;
  height: 35px;
  border: 1px solid goldenrod;
  border-radius: 5px;
  background-color: rgba(218, 165, 32, 0.5);
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center; }

.ol-rotate-reset,
.ol-attribution,
.ol-compass,
button[title="Attributions"] {
  display: none; }

.contact-image {
  padding-top: 20px; }
  @media only screen and (max-width: 767px) {
    .contact-image {
      display: flex;
      justify-content: center;
      align-items: center; } }
  @media only screen and (max-width: 767px) {
    .contact-image {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 0; } }
  .contact-image .contact-image {
    height: 240px;
    width: 240px;
    margin-right: 20px; }

.buttons-class {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 40px; }
  .buttons-class button {
    color: white;
    width: 45%; }
    .buttons-class button a {
      color: white; }
  @media only screen and (max-width: 430px) {
    .buttons-class {
      flex-direction: column; }
      .buttons-class button {
        width: 90%;
        height: 60px; } }
  @media only screen and (max-width: 767px) {
    .buttons-class button {
      height: 75px; } }

.flip-card {
  aspect-ratio: 1/1;
  width: 92%;
  background-color: transparent;
  border-radius: 50%;
  perspective: 1000px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 576px) {
    .flip-card {
      width: 65%;
      margin: auto;
      margin-bottom: 30px; } }

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 50%; }

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg); }

.flip-card-front,
.flip-card-back {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%; }

.flip-card-front {
  background-image: url(./assets/back-circle3.png);
  background-size: contain;
  background-repeat: no-repeat;
  color: white !important;
  aspect-ratio: 1/1;
  width: 100%; }
  .flip-card-front p {
    color: white;
    font-family: "Barlow", sans-serif;
    font-style: italic;
    font-size: 18px;
    margin: 0;
    padding: 0 40px;
    text-align: center;
    font-weight: 600; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .flip-card-front p {
        font-size: 14px; } }

.flip-card-back {
  background-image: url(./assets/back-circle3.png);
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
  transform: rotateY(180deg); }
  .flip-card-back p {
    color: white;
    font-family: "Barlow", sans-serif;
    font-style: italic;
    padding: 10px;
    font-size: 18px;
    margin: 0;
    padding: 0 40px;
    text-align: center;
    font-weight: bolder; }

.prestations-circle {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 50%;
  background-color: violet;
  margin-right: 10px; }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Barlow', sans-serif;
  background-color: #f5f5f5; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Barlow', sans-serif; }

button {
  cursor: pointer; }

#root {
  position: relative; }

.date-time-picker-wrap {
  cursor: pointer;
  margin-bottom: 30px;
  height: 54px;
  border-radius: 6px;
  outline: none;
  border: 1px solid #C1A35E;
  background-color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  width: 100%;
  display: flex;
  position: relative; }
  .date-time-picker-wrap .rdrCalendarWrapper {
    position: absolute;
    top: 100%;
    z-index: 9999;
    -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1); }
  .date-time-picker-wrap > button {
    background-color: #F5F5F5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0;
    padding: 10px;
    height: 100%;
    margin-right: 15px; }
    .date-time-picker-wrap > button svg {
      stroke: #C1A35E; }
  .date-time-picker-wrap .save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px 30px; }
    .date-time-picker-wrap .save-button::after {
      content: ' ';
      display: block;
      width: 8px;
      height: 14px;
      border-right: 2px solid #2196F3;
      border-bottom: 2px solid #2196F3;
      transform: rotate(45deg); }
  .date-time-picker-wrap input {
    cursor: pointer;
    border: 0;
    height: 100%; }
    .date-time-picker-wrap input:focus {
      border-bottom: 2px solid #2196F3;
      outline: none; }
  .date-time-picker-wrap input:disabled {
    background-color: #FFFFFF; }
  .date-time-picker-wrap span {
    font-weight: 600;
    margin: 0 3px; }
  .date-time-picker-wrap .date-input {
    display: flex;
    align-items: center; }
    .date-time-picker-wrap .date-input input {
      text-align: left; }
    .date-time-picker-wrap .date-input .digit2 {
      width: 28px; }
    .date-time-picker-wrap .date-input .digit4 {
      width: 70px; }
  .date-time-picker-wrap .time-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px; }
    .date-time-picker-wrap .time-input input {
      text-align: left;
      font-size: 14px; }
    .date-time-picker-wrap .time-input .digit2 {
      width: 22px; }
    .date-time-picker-wrap .time-input .buttons-wrap {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: 5px; }
      .date-time-picker-wrap .time-input .buttons-wrap .inc {
        background-color: transparent;
        border: 0;
        outline: none; }
        .date-time-picker-wrap .time-input .buttons-wrap .inc::after {
          content: ' ';
          border-left: 2px solid #ccc;
          border-top: 2px solid #ccc;
          display: block;
          transform: rotate(45deg);
          width: 10px;
          height: 10px; }
        .date-time-picker-wrap .time-input .buttons-wrap .inc:hover::after {
          border-left: 2px solid #C1A35E;
          border-top: 2px solid #C1A35E; }
      .date-time-picker-wrap .time-input .buttons-wrap .dec {
        background-color: transparent;
        border: 0;
        outline: none; }
        .date-time-picker-wrap .time-input .buttons-wrap .dec::after {
          content: ' ';
          border-left: 2px solid #ccc;
          border-top: 2px solid #ccc;
          display: block;
          transform: rotate(-135deg);
          width: 10px;
          height: 10px; }
        .date-time-picker-wrap .time-input .buttons-wrap .dec:hover::after {
          border-left: 2px solid #C1A35E;
          border-top: 2px solid #C1A35E; }

.time-error {
  border: 2px solid red; }

.time-correct {
  border: 2px solid green; }

.input-field {
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #C1A35E;
  outline: none;
  width: 100%;
  margin-bottom: 20px; }

.required {
  border: 1px solid red; }

.wrapper {
  margin-top: 270px;
  position: relative;
  overflow-y: hidden; }
  @media only screen and (max-width: 1023px) {
    .wrapper {
      margin-top: 130px; } }

.page-wrap {
  position: relative;
  z-index: 1; }

.button {
  position: relative;
  overflow: hidden; }
  .button .overlay {
    position: absolute;
    width: 700px;
    height: 700px;
    margin-left: -350px;
    margin-top: -350px;
    border-radius: 50%;
    -webkit-animation-name: grow;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: grow;
    animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }

@-webkit-keyframes grow {
  from {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 700px;
    height: 700px;
    margin-left: -350px;
    margin-top: -350px; } }

@keyframes grow {
  from {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 700px;
    height: 700px;
    margin-left: -350px;
    margin-top: -350px; } }
  .button .content {
    z-index: 1;
    position: relative; }

@keyframes animation-rotate {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(15deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes animation-rotate1 {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(-15deg); }
  100% {
    transform: rotate(0deg); } }

.animation-1 {
  position: absolute;
  top: 290px;
  left: 118px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-1 {
      display: none; } }

.animation-2 {
  position: absolute;
  top: 916px;
  left: 205px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-2 {
      display: none; } }

.animation-3 {
  position: absolute;
  top: 1149px;
  left: 172px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-3 {
      display: none; } }

.animation-4 {
  position: absolute;
  top: 2224px;
  left: 102px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-4 {
      display: none; } }

.animation-5 {
  position: absolute;
  top: 3475px;
  left: 105px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-5 {
      display: none; } }

.animation-6 {
  position: absolute;
  top: 4085px;
  left: 202px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-6 {
      display: none; } }

.animation-7 {
  position: absolute;
  top: 4410px;
  left: 176px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-7 {
      display: none; } }

.animation-8 {
  position: absolute;
  top: 4826px;
  left: 118px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .animation-8 {
      display: none; } }

.right-animation-1 {
  position: absolute;
  top: 1101px;
  right: 118px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-1 {
      display: none; } }

.right-animation-2 {
  position: absolute;
  top: 1326px;
  right: 205px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-2 {
      display: none; } }

.right-animation-3 {
  position: absolute;
  top: 1692px;
  right: 172px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-3 {
      display: none; } }

.right-animation-4 {
  position: absolute;
  top: 2112px;
  right: 102px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-4 {
      display: none; } }

.right-animation-5 {
  position: absolute;
  top: 2500px;
  right: 105px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-5 {
      display: none; } }

.right-animation-6 {
  position: absolute;
  top: 3052px;
  right: 202px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-6 {
      display: none; } }

.right-animation-7 {
  position: absolute;
  top: 3368px;
  right: 176px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-7 {
      display: none; } }

.right-animation-8 {
  position: absolute;
  top: 4099px;
  right: 118px;
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-8 {
      display: none; } }

.right-animation-9 {
  position: absolute;
  top: 5022px;
  right: 118px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-9 {
      display: none; } }

.right-animation-10 {
  position: absolute;
  top: 90px;
  right: 118px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-10 {
      display: none; } }

.right-animation-11 {
  position: absolute;
  top: 690px;
  right: 200px;
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
  @media only screen and (max-width: 1400px) {
    .right-animation-11 {
      display: none; } }

#map {
  z-index: 999; }
